import {inject, Injectable} from '@angular/core';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {UmsatzService} from './umsatz.service';
import {OverviewService} from '../modules/overview/overview.service';
import {Router} from '@angular/router';
import {UtilityWidgetService} from '../modules/widget/utility-widget/utility-widget.service';
import {from} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {BadgeValueService} from './badge-values.service';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {InhaberEntitiesActions} from '@adnova/jf-ng-components';


export interface StartAssignmentDataV1 {
  readonly inhaberId: string;
}

export interface UmsatzAssignedDataV1 {
  readonly inhaberId: string;
  readonly umsatzId: string;
}

export interface OpenToClarifyDataV1 {
  readonly inhaberId: string;
}

export interface OpenToAssignDataV1 {
  readonly inhaberId: string;
}

export interface OpenSingleUmsatzDataV1 {
  readonly inhaberId: string;
  readonly umsatzId: string;
  readonly empfaengerAuftraggeber?: string;
}

export interface OpenBankkontoDataV1 {
  readonly inhaberId: string;
  readonly bankkontoId: string;
}

export interface UpdatedBetriebDataV1 {
  readonly betriebId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  private _portalMessageBroker = inject(PortalMessageBroker);
  private _store = inject(Store<AppState>);
  private _logger = inject(NGXLogger);
  private _router = inject(Router);
  private _utilityWidgetService = inject(UtilityWidgetService);
  private _umsatzService = inject(UmsatzService);
  private _overviewService = inject(OverviewService);
  private _badgeValueService = inject(BadgeValueService);


  /**
   * Intent-Id für den Broadcast zur Information über einen erstellten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_belege.created_v1.json
   *
   * @private
   */
  private readonly _belegCreatedIdV1 = 'de.just-farming:belege-online:beleg.created';

  /**
   * Intent-Id für den Broadcast zur Information über einen gelöschten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_belege.deleted_v1.json
   *
   * @private
   */
  private readonly _belegDeletedIdV1 = 'de.just-farming:belege-online:beleg.deleted';

  /**
   * Intent-ID für den Broadcast zur Information über einen zugewiesenen Kontoumsatz
   *
   * JSON Schema: src/assets/intents/de.just-farming.kontoumaetze_umsatz.assigned_v1.json
   *
   * @private
   */
  private readonly _koasUmsatzAssignedV1 = 'de.just-farming:kontoumsaetze:umsatz.assigned';

  /**
   * Intent-Id für das Öffnen der zu klärenden Kontoumsätze
   *
   * JSON Schema: src/assets/intents/de.just-farming.kontoumaetze_toclarify.open_v1.json
   *
   * @private
   */
  private readonly _koasOpenToClarifyV1 = 'de.just-farming:kontoumsaetze:toclarify.open';

  /**
   * Intent-Id für das Öffnen der zuzuordnenden Kontoumsätze
   *
   * JSON Schema: src/assets/intents/de.just-farming.kontoumaetze_toassign.open_v1.json
   *
   * @private
   */
  private readonly _koasOpenToAssignV1 = 'de.just-farming:kontoumsaetze:toassign.open';

  /**
   * Intent-Id für das Öffnen eines einzelnen Kontoumsatzes
   *
   * JSON Schema: src/assets/intents/de.just-farming.kontoumaetze_singleumsatz.open_v1.json
   *
   * @private
   */
  private readonly _koasOpenSingleUmsatzV1 = 'de.just-farming:kontoumsaetze:singleumsatz.open';

  /**
   * Intent-Id für das Anzeigen der Umsätze eines Bankkontos
   *
   * JSON Schema: src/assets/intents/de.just-farming.kontoumaetze_bankkonto.open_v1.json
   *
   * @private
   */
  private readonly _koasOpenBankkontoV1 = 'de.just-farming:kontoumsaetze:bankkonto.open';

  /**
   * Intent-Id für den Broadcast zur Information über einen aktualisierten Betrieb
   *
   * @private
   */
  private readonly _betriebUpdatedIdV1 = 'de.just-farming:betriebsinformationen:betrieb.updated';

  constructor() {
    if (this.isRunningInPortal()) {

      // INFO: Beleg erstellt.
      this._portalMessageBroker.registerIntentCallback(this._belegCreatedIdV1, '1', (data => {
        this.handleBelegCreated(data);
      }));

      // INFO: Beleg gelöscht.
      this._portalMessageBroker.registerIntentCallback(this._belegDeletedIdV1, '1', (data => {
        this.handleBelegDeleted(data);
      }));

      // INFO: Umsatz zugewiesen.
      this._portalMessageBroker.registerIntentCallback(this._koasUmsatzAssignedV1, '1', (data => {
        this.handleUmsatzAssigned(data);
      }));

      // INFO: Öffnen der Klärung-Seite.
      this._portalMessageBroker.registerIntentCallback(this._koasOpenToClarifyV1, '1', (data => {
        this.handleUmsatzOpenToClarify(data);
      }));

      // INFO: Öffnen der Zuweisungs-Seite.
      this._portalMessageBroker.registerIntentCallback(this._koasOpenToAssignV1, '1', (data => {
        this.handleUmsatzOpenToAssign(data);
      }));

      // INFO: Öffnen eines einzelnen Umsatzes.
      this._portalMessageBroker.registerIntentCallback(this._koasOpenSingleUmsatzV1, '1', (data => {
        this.handleUmsatzOpenSingleUmsatz(data);
      }));

      // INFO: Öffnen der Umsätze eines Bankkontos.
      this._portalMessageBroker.registerIntentCallback(this._koasOpenBankkontoV1, '1', (data => {
        this.handleUmsatzOpenBankkonto(data);
      }));

      // INFO: Betrieb aktualisiert.
      this._portalMessageBroker.registerIntentCallback(this._betriebUpdatedIdV1, '1', (data => {
        this.handleBetriebUpdated(data);
      }));

      this._portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft, ob die App im Portal läuft.
   */
  public isRunningInPortal(): boolean {
    return this._portalMessageBroker.isRunningInPortal();
  }

  /**
   * Wird ein Beleg über eine andere App angelegt, so soll die Zuordnung neu angestoßen werden.
   *
   * @param data Intent-Daten
   */
  public handleBelegCreated(
    data: StartAssignmentDataV1
  ): void {
    this._umsatzService.startAssignment(data.inhaberId);
  }

  /**
   * Wurde ein Beleg über einer andere App gelöscht, so sollen sich die Werte aktualisieren.
   *
   * @param data Intent-Daten
   */
  public handleBelegDeleted(
    data: StartAssignmentDataV1
  ): void {
    const inhaberId = data.inhaberId;
    this._badgeValueService.updateBadgeValue(inhaberId);

    // Die Komponente muss nur aktualisiert werden, wenn sich der Anwender auf der Übersicht-Seite des Inhabers befindet
    const url = this._router.url;
    if (url.includes(inhaberId) && url.includes('overview')) {
      this._overviewService.updateUmsaetzeToAssignCount(inhaberId);
    }
  }

  /**
   * Wurde ein Umsatz zugewiesen, so sollen sich die Werte z.B. im Widget aktualisieren.
   *
   * @param data Intent-Daten
   */
  public handleUmsatzAssigned(data: UmsatzAssignedDataV1) {
    /*
     * INFO: Wenn das Widget gerade den geupdateten Inhaber anzeigt,
     * wird das InhaberId-Subject getriggert. Dadurch werden alle Daten
     * dieses Inhabers neu geladen.
     */
    const inhaberId = this._utilityWidgetService.inhaberId.value;
    if (inhaberId === data.inhaberId) {
      this._utilityWidgetService.reload$.next();
    }
  }

  public doUmsatzAssigned(data: UmsatzAssignedDataV1): void {
    this.doEmit(this._koasUmsatzAssignedV1, '1', data);
  }

  /**
   * Öffne die Liste der zu klärenden Umsätze
   *
   * @param data Intent-Daten
   */
  public handleUmsatzOpenToClarify(data: OpenToClarifyDataV1) {
    this._router.navigateByUrl('/to-clarify/inhaber/' + data.inhaberId);
  }

  public doUmsatzOpenToClarify(data: OpenToClarifyDataV1): void {
    this.doEmit(this._koasOpenToClarifyV1, '1', data);
  }

  /**
   * Öffne die Liste der zuzuweisenden Umsätze
   *
   * @param data Intent-Daten
   */
  public handleUmsatzOpenToAssign(data: OpenToAssignDataV1) {
    this._router.navigateByUrl('/to-assign/inhaber/' + data.inhaberId);
  }

  public doUmsatzOpenToAssign(data: OpenToAssignDataV1): void {
    this.doEmit(this._koasOpenToAssignV1, '1', data);
  }

  /**
   * Öffne die Liste aller Umsätze und filtere sie auf einen bestimmten Umsatz ein
   *
   * @param data Intent-Daten
   */
  public handleUmsatzOpenSingleUmsatz(data: OpenSingleUmsatzDataV1) {
    /*
     * INFO: Es wird auf die Seite "Alle Umsätze" weitergeleitet und auf
     * den mitgegebenen Umsatz eingefiltert.
     */
    this._router.navigate(
      ['/all-assigned/inhaber/' + data.inhaberId],
      {
        queryParams: {
          umsaetze: data.umsatzId,
          umsaetzeTitle: data.empfaengerAuftraggeber,
        },
        state: {
          avoidClearFilter: true,
        }
      }
    );
  }

  public doUmsatzOpenSingleUmsatz(data: OpenSingleUmsatzDataV1): void {
    this.doEmit(this._koasOpenSingleUmsatzV1, '1', data);
  }

  /**
   * Öffne die Liste aller Umsätze und filtere sie auf ein bestimmtes Bankkonto ein
   *
   * @param data Intent-Daten
   */
  public handleUmsatzOpenBankkonto(data: OpenBankkontoDataV1) {
    /*
     * INFO: Es wird auf die Seite "Alle Umsätze" weitergeleitet und auf
     * den mitgegebenen Umsatz eingefiltert.
     */
    this._router.navigate(
      ['/all-assigned/inhaber/' + data.inhaberId],
      {
        queryParams: {
          bank: data.bankkontoId,
        },
        state: {
          avoidClearFilter: true,
        }
      }
    );
  }

  /**
   * Löst das Aktualisieren der Daten des Betriebs aus.
   *
   * @param data
   */
  handleBetriebUpdated(
    data: UpdatedBetriebDataV1,
  ): void {
    this._logger.debug('betrieb updated intent received for betriebId:', data.betriebId);

    // INFO: Die Daten der Betriebe in der Betriebsauswahl müssen aktualisiert werden.
    this._store.dispatch(InhaberEntitiesActions.loadInhabers());
  }

  public doUmsatzOpenBankkonto(data: OpenBankkontoDataV1): void {
    this.doEmit(this._koasOpenBankkontoV1, '1', data);
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this._portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}

