/**
 * Kontoumsatzassistent
 * Die Kontoumsätze eines Bankkontos gelangen von der jeweiligen Bank der Anwender über die Datendrehscheibe in eine Datenbank und können von dort abgefragt werden. Den Kontoumsätzen können automatisch Belege zugeordnet werden. Gibt es keinen Umsatz der genau passt, werden dem Anwender Vorschläge gemacht aus denen manuell ausgewählt werden kann. Die Funktionen des Moduls Kontoumsatzassistent in ADNOVA+ werden mit Hilfe des KoAs-Services für das Portal zur Verfügung gestellt. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AbrufenResultDTO } from '../model/models';
import { BelegVorschlagDTO } from '../model/models';
import { CountUmsatzResponseDTO } from '../model/models';
import { ErrorMessageDTO } from '../model/models';
import { FilterUmsatzDTO } from '../model/models';
import { ManuelleZuordnungRequestDTO } from '../model/models';
import { PageableUmsatzDTO } from '../model/models';
import { UmsatzDTO } from '../model/models';
import { UpdateUmsatzRequestDTO } from '../model/models';
import { ZuordnungResultDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class UmsatzService {

    protected basePath = 'https://api.adnova.de/koas-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Ermittelt die Anzahl an Kontoumsätzen
     * Ermittelt die Anzahl an Kontoumsätzen zum Inhaber
     * @param inhaberId Identifikation eines Inhabers
     * @param umsatzFilter Objekt zum Filtern von Kontoumsätzen
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public countUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CountUmsatzResponseDTO>;
    public countUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CountUmsatzResponseDTO>>;
    public countUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CountUmsatzResponseDTO>>;
    public countUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling countUmsaetze.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (umsatzFilter !== undefined && umsatzFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>umsatzFilter, 'umsatzFilter');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CountUmsatzResponseDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze/count`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen eines Kontoumsatzes
     * Laden eines einzelnen Kontoumsatzes
     * @param inhaberId Identifikation eines Inhabers
     * @param umsatzId Identifikation eines Kontoumsatzes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUmsatz(inhaberId: string, umsatzId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UmsatzDTO>;
    public getUmsatz(inhaberId: string, umsatzId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UmsatzDTO>>;
    public getUmsatz(inhaberId: string, umsatzId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UmsatzDTO>>;
    public getUmsatz(inhaberId: string, umsatzId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getUmsatz.');
        }
        if (umsatzId === null || umsatzId === undefined) {
            throw new Error('Required parameter umsatzId was null or undefined when calling getUmsatz.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<UmsatzDTO>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze/${encodeURIComponent(String(umsatzId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen der Vorschläge zu einem Kontoumsatz
     * Ermittelt mögliche passende Belege zum Kontoumsatz. Die dabei ermittelten Punkte werden dann im Result als Heuristikpunkte zurückgegeben. Eine höhere Punktzahl gibt dabei eine bessere Übereinstimmung zwischen Kontoumsatz- und Belegwerten an. 
     * @param inhaberId Identifikation eines Inhabers
     * @param umsatzId Identifikation eines Kontoumsatzes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVorschlaege(inhaberId: string, umsatzId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BelegVorschlagDTO>>;
    public getVorschlaege(inhaberId: string, umsatzId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BelegVorschlagDTO>>>;
    public getVorschlaege(inhaberId: string, umsatzId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BelegVorschlagDTO>>>;
    public getVorschlaege(inhaberId: string, umsatzId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling getVorschlaege.');
        }
        if (umsatzId === null || umsatzId === undefined) {
            throw new Error('Required parameter umsatzId was null or undefined when calling getVorschlaege.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BelegVorschlagDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze/${encodeURIComponent(String(umsatzId))}/vorschlaege`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Lesen aller Kontoumsätze
     * Lesen aller Kontoumsätze zum Inhaber
     * @param inhaberId Identifikation eines Inhabers
     * @param umsatzFilter Objekt zum Filtern von Kontoumsätzen
     * @param umsatzPageable Objekt für das OffSet, Limit und die Sortierung beim Laden von Kontoumsätzen
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, umsatzPageable?: PageableUmsatzDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<UmsatzDTO>>;
    public readUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, umsatzPageable?: PageableUmsatzDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<UmsatzDTO>>>;
    public readUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, umsatzPageable?: PageableUmsatzDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<UmsatzDTO>>>;
    public readUmsaetze(inhaberId: string, umsatzFilter?: FilterUmsatzDTO, umsatzPageable?: PageableUmsatzDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling readUmsaetze.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (umsatzFilter !== undefined && umsatzFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>umsatzFilter, 'umsatzFilter');
        }
        if (umsatzPageable !== undefined && umsatzPageable !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>umsatzPageable, 'umsatzPageable');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<UmsatzDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starten der automatischen Zuordnung von Kontoumsätzen
     * Startet die asynchrone automatische Zuordnung von Kontoumsätzen zu Belegen für das Bankkonto des Inhabers
     * @param inhaberId Identifikation eines Inhabers
     * @param bankkontoId Identifikation eines Bankkontos
     * @param manuelleZuordnungRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startAutomatischeZuordnung(inhaberId: string, bankkontoId: string, manuelleZuordnungRequestDTO?: ManuelleZuordnungRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public startAutomatischeZuordnung(inhaberId: string, bankkontoId: string, manuelleZuordnungRequestDTO?: ManuelleZuordnungRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public startAutomatischeZuordnung(inhaberId: string, bankkontoId: string, manuelleZuordnungRequestDTO?: ManuelleZuordnungRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public startAutomatischeZuordnung(inhaberId: string, bankkontoId: string, manuelleZuordnungRequestDTO?: ManuelleZuordnungRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling startAutomatischeZuordnung.');
        }
        if (bankkontoId === null || bankkontoId === undefined) {
            throw new Error('Required parameter bankkontoId was null or undefined when calling startAutomatischeZuordnung.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze/automatischezuordnung${encodeURIComponent(String(bankkontoId))}`,
            manuelleZuordnungRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Starten der automatischen Zuordnung von Kontoumsätzen
     * Startet die automatische Zuordnung von Kontoumsätzen zu Belegen für alle Bankkonten des Inhabers
     * @param inhaberId Identifikation eines Inhabers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public startZuordnung(inhaberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<ZuordnungResultDTO>>;
    public startZuordnung(inhaberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<ZuordnungResultDTO>>>;
    public startZuordnung(inhaberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<ZuordnungResultDTO>>>;
    public startZuordnung(inhaberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling startZuordnung.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<ZuordnungResultDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze/zuordnung`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Abrufen der Kontoumsätze
     * Ruft über die Datendrehscheibe die aktuellen Kontoumsätze für alle Bankkonten des Inhabers ab
     * @param inhaberId Identifikation eines Inhabers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public umsaetzeAbrufen(inhaberId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<AbrufenResultDTO>>;
    public umsaetzeAbrufen(inhaberId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<AbrufenResultDTO>>>;
    public umsaetzeAbrufen(inhaberId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<AbrufenResultDTO>>>;
    public umsaetzeAbrufen(inhaberId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling umsaetzeAbrufen.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<AbrufenResultDTO>>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze/abrufen`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisieren eines Kontoumsatzes
     * Aktualisieren der Daten zu einem Kontoumsatz
     * @param inhaberId Identifikation eines Inhabers
     * @param umsatzId Identifikation eines Kontoumsatzes
     * @param updateUmsatzRequestDTO 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUmsatz(inhaberId: string, umsatzId: string, updateUmsatzRequestDTO: UpdateUmsatzRequestDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<any>;
    public updateUmsatz(inhaberId: string, umsatzId: string, updateUmsatzRequestDTO: UpdateUmsatzRequestDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<any>>;
    public updateUmsatz(inhaberId: string, umsatzId: string, updateUmsatzRequestDTO: UpdateUmsatzRequestDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<any>>;
    public updateUmsatz(inhaberId: string, umsatzId: string, updateUmsatzRequestDTO: UpdateUmsatzRequestDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (inhaberId === null || inhaberId === undefined) {
            throw new Error('Required parameter inhaberId was null or undefined when calling updateUmsatz.');
        }
        if (umsatzId === null || umsatzId === undefined) {
            throw new Error('Required parameter umsatzId was null or undefined when calling updateUmsatz.');
        }
        if (updateUmsatzRequestDTO === null || updateUmsatzRequestDTO === undefined) {
            throw new Error('Required parameter updateUmsatzRequestDTO was null or undefined when calling updateUmsatz.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (oauth-ld) required
        credential = this.configuration.lookupCredential('oauth-ld');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/inhaber/${encodeURIComponent(String(inhaberId))}/umsaetze/${encodeURIComponent(String(umsatzId))}`,
            updateUmsatzRequestDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
